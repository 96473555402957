body, html {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(to right, #b4e1ff, rgb(10, 167, 133));
}

.app {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.container {
  width: 300px; /* Adjust the width as needed */
  background-color: #fff;
  padding: 20px;
  margin-top: 20vh;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Grid{

  display: flex
}
form {
  width: 100%;
}

input {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  width: 300px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: rgb(10, 167, 133);
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #087c5b;
}

.icon {
  height: 100px; /* Adjust the size as needed */
  width: 150px; /* Adjust the size as needed */
  margin-bottom: 20px;
}
.curved {
  position: relative;
  background:  linear-gradient(to right, #b4e1ff, rgb(10, 167, 133));
  height: 22vh;
  border-bottom-left-radius: 50% 20%;
  border-bottom-right-radius: 50% 20%;
}




.h-divider {
  margin: auto;
  margin-top: 20px;
  width: 80%;
  position: relative;
}

.h-divider .shadow {
  overflow: hidden;
  height: 20px;
}

.h-divider .shadow:after {
  content: '';
  display: block;
  margin: -25px auto 0;
  width: 100%;
  height: 25px;
  border-radius: 125px/12px;
  box-shadow: 0 0 8px black;
}
