.container {
    
    text-align: center;
    justify-content: center;
    justify-items: center;
    
  }
  
  .button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  .count {
    font-size: 24px;
    margin-top: 20px;
  }
  h1 {

    text-shadow: 4px 4px 2px rgba(0,0,0,0.6);
  }
  .MainBodyContainer{
    justify-items: center;
    justify-content: center;
    align-items: center;
  }
  